'use client'

import BlockPicture from '~@Atoms/block-picture/block-picture'
import LinkItem from '~@Atoms/link-item/link-item'
import CursorCustom from '~@Atoms/cursor-custom/cursor-custom'
import DateInfo from '~@Atoms/date-info/date-info'

import { ITeaserCard } from '~@Types/components/ITeaserCard'

import useCheckMobile from '~@Hooks/useCheckMobile'

const TeaserCard = ({
  picture,
  surtitle,
  title,
  date,
  description,
  link,
  tags,
}: ITeaserCard) => {
  const hasLink = !!link?.href
  const cursorSize = 92

  const isMobile = useCheckMobile()

  return (
    <article className="TeaserCard w-full relative transform-none">
      <LinkItem
        {...link}
        className={`w-full h-full flex flex-col group items-start ${!isMobile ? 'cursor-none' : ''}`}
        aria-labelledby="teaser-title"
      >
        <div className="TeaserCard-wrapper w-full relative overflow-hidden mb-lg">
          <div className="w-full relative overflow-hidden rounded-2xl transform-gpu bg-grey-grey dark:bg-black-40 lg:rounded-3xl">
            <div className="w-full relative overflow-hidden">
              <div className="w-full transform scale-110 transition-transform tablet:group-hover:-translate-y-sm">
                <div className="TeaserCard-picture w-full pt-[75%] relative overflow-hidden">
                  {picture && (
                    <BlockPicture
                      className="absolute top-none left-none w-full h-full object-cover"
                      {...picture}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {tags && tags.length > 0 && (
            <div className="TeaserCard-tags max-w-[85%] absolute bottom-none -right-4xs z-20 bg-white-white py-xs pl-xs transform-gpu rotate-001 rounded-tl-lg transition-transform duration-300 tablet:translate-y-full tablet:group-hover:-translate-y-none">
              <ul
                className="TeaserCard-tagsList flex flex-wrap items-center justify-end gap-xs typeface-subtitle2-standard text-brand-primary"
                aria-label="Tags"
              >
                {tags.map((tag, index) => (
                  <li
                    key={index}
                    className={`TeaserCard-tagsItem px-xs py-2xs bg-[#EDDEE5] rounded-full ${index > 1 ? 'desktop:hidden' : ''}`}
                  >
                    {tag}
                  </li>
                ))}
                {tags.length > 2 && (
                  <li className="typeface-button2-small-standard text-brand-primary desktop:hidden">
                    + {tags.length - 2}
                  </li>
                )}
              </ul>
              <div
                className="absolute tablet:hidden tablet:group-hover:block top-none right-none transform -translate-y-full w-2xl h-2xl overflow-hidden after:content-[''] after:absolute after:top-none after:left-none after:w-2xl after:h-2xl after:rounded-br-xl after:shadow-[0_0_0_20px_theme(colors.white-white)]"
                aria-hidden="true"
              ></div>
              <div
                className="absolute tablet:hidden tablet:group-hover:block bottom-none left-none transform -translate-x-full w-2xl h-2xl overflow-hidden after:content-[''] after:absolute after:top-none after:left-none after:w-2xl after:h-2xl after:rounded-br-xl after:shadow-[0_0_0_20px_theme(colors.white-white)]"
                aria-hidden="true"
              ></div>
            </div>
          )}
        </div>
        {date && (
          <DateInfo
            className="mb-sm"
            date={date.toString()}
            dateTime={date.toString()}
          />
        )}
        {surtitle && (
          <p className="TeaserCard-category typeface-subtitle1-standard text-brand-primary mb-sm">
            {surtitle}
          </p>
        )}
        <h3
          id="teaser-title"
          className="TeaserCard-title typeface-heading-a5-standard text-brand-primary text-balance"
        >
          {title}
        </h3>
        {description && (
          <p className="TeaserCard-description typeface-subtitle1-standard mt-md">
            {description}
          </p>
        )}
        {hasLink && !isMobile && <CursorCustom cursorSize={cursorSize} />}
      </LinkItem>
    </article>
  )
}

export default TeaserCard
