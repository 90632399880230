import { IDateInfo } from '~@Types/components/IDateInfo'

export default function DateInfo({ className, date, dateTime }: IDateInfo) {
  const parsedDate = new Date(dateTime)
  const formattedDateTime = !isNaN(parsedDate.getTime())
    ? parsedDate.toISOString()
    : ''

  return (
    <time
      dateTime={formattedDateTime}
      className={`DateInfo ${className} inline-block rounded-full py-2xs px-xs typeface-subtitle2-standard bg-brand-primary/15 text-brand-primary`}
    >
      {date}
    </time>
  )
}
