import { twMerge } from 'tailwind-merge'
import ButtonSecondary from '~@Components/atoms/button-secondary/button-secondary'
import { IBlockHeading } from '~@Types/components/IBlockHeading'

const BlockHeading = ({
  className,
  title,
  surtitle,
  link,
  isReversed,
  isAlternate,
}: IBlockHeading) => {
  const hasLink = !!link?.link?.href
  const textColor = isReversed ? 'text-white-white' : 'text-brand-primary'
  const dotColor = isReversed
    ? 'before:bg-white-white'
    : 'before:bg-brand-primary'

  const commonSurtitleClasses = `BlockHeading-surtitle typeface-subtitle1-standard mr-lg mb-xs before:content-[''] before:block before:w-xs before:h-xs before:rounded-full ${dotColor}`
  const commonTitleClasses = 'BlockHeading-title typeface-heading-a3-standard'
  const commonLinkClasses =
    'BlockHeading-action flex items-center mt-xl tablet:col-start-2 tablet:col-end-9 desktop:items-start'

  return (
    <div className={twMerge(`BlockHeading mb-xl ${textColor}`, className)}>
      {isAlternate ? (
        <div className="tablet:flex tablet:justify-between tablet:items-start">
          {surtitle && (
            <p
              className={`${commonSurtitleClasses} flex items-center gap-x-xs tablet:w-1/3`}
            >
              {surtitle}
            </p>
          )}
          <div className="tablet:w-2/3">
            <h2 className={`${commonTitleClasses} tablet:indent-4xl`}>
              {title}
            </h2>
            {hasLink && (
              <div className={commonLinkClasses}>
                <ButtonSecondary className="BlockHeading-button" {...link} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <p
            className={`${commonSurtitleClasses} relative pl-lg before:absolute before:left-none before:top-1/2 before:-translate-y-1/2 before:mr-xs desktop:mb-none desktop:inline desktop:-top-[8px]`}
          >
            {surtitle}
          </p>
          <h2 className={`${commonTitleClasses} desktop:pl-lg desktop:inline`}>
            {title}
          </h2>
          {hasLink && (
            <div className={commonLinkClasses}>
              <ButtonSecondary className="BlockHeading-button" {...link} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BlockHeading
