import LinkItem from '~@Atoms/link-item/link-item'
import { ArrowUpRight } from '@phosphor-icons/react/dist/ssr'
import { IButton } from '~@Types/components/IButton'
import { twMerge } from 'tailwind-merge'

export default function ButtonSecondary({
  className,
  link,
  label,
  onClick,
}: IButton) {
  return (
    <LinkItem
      onClick={onClick ? () => onClick() : undefined}
      className={twMerge(
        'group flex items-center typeface-button1-special',
        className
      )}
      {...link}
    >
      <span className="text-white-white bg-brand-secondary group px-md py-sm rounded-full inline-block">
        {label}
      </span>
      <span
        aria-hidden="true"
        className="rounded-full bg-brand-secondary p-sm ease-in-out transition duration-300 group-hover:translate-x-xs group-hover:rotate-45"
      >
        <ArrowUpRight size={16} color="white" weight="bold" />
      </span>
    </LinkItem>
  )
}
