'use client'

import BlockHeading from '~@Molecules/block-heading/block-heading'
import TeaserCard from '~@Molecules/teaser-card/teaser-card'
import ButtonSpecial from '~@Atoms/button-special/button-special'

import { ISectionWall } from '~@Types/components/ISectionWall'

import useCheckMobile from '~@Hooks/useCheckMobile'
import useShowMore from '~@Hooks/handleShowMore'

const SectionWall = ({
  displayBTN,
  title,
  surtitle,
  link,
  items,
  buttonLabel,
}: ISectionWall) => {
  const { showAll, isButtonVisible, handleShowMore, fourthItemRef } =
    useShowMore()
  const isMobile = useCheckMobile()

  return (
    <section className="SectionWall">
      {title && (
        <div className="SectionWall-container px-lg tablet:px-3xl desktop:grid desktop:grid-cols-12 desktop:gap-x-3xl ">
          <BlockHeading
            className="tablet:col-start-2 tablet:col-end-8"
            title={title}
            surtitle={surtitle ? surtitle : ''}
            link={link}
          />
        </div>
      )}
      {items && (
        <ul className="SectionWall-items px-lg tablet:px-3xl grid grid-cols-1 gap-xl mt-xl tablet:grid-cols-2 desktop:grid-cols-3 desktop:gap-3xl desktop:mt-none">
          {items.map((item, index) => {
            const shouldHide = isMobile && index > 2 && !showAll && !displayBTN

            if (shouldHide) {
              return null
            }

            return (
              <li
                ref={index === 3 ? fourthItemRef : null}
                key={index}
                className={`SectionWall-item mb-2xl tablet:mb-none ${
                  index % 2 === 0 ? 'tablet:mt-4xl' : 'tablet:mt-none'
                } desktop:mb-none ${
                  index % 3 === 0
                    ? 'desktop:mt-8xl'
                    : index % 3 === 1
                      ? 'desktop:mt-4xl'
                      : 'desktop:mt-none'
                }`}
              >
                <TeaserCard {...item} />
              </li>
            )
          })}
        </ul>
      )}
      {items.length > 3 && !showAll && !displayBTN && (
        <div
          className={`SectionCorporate-action flex justify-center desktop:hidden transition-opacity duration-500 ${isButtonVisible ? 'opacity-100' : 'opacity-0'} p-lg pb-4xl`}
        >
          <ButtonSpecial
            className="cursor-pointer"
            label={buttonLabel}
            isReversed
            link={{
              tagElt: 'button',
              onClick: handleShowMore,
            }}
          />
        </div>
      )}
    </section>
  )
}

export default SectionWall
