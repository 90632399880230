'use client'
import { useCallback, useMemo } from 'react'

/**
 * A custom hook that manages cursor behavior and position within a container
 * @param {Object} params - Hook parameters
 * @param {number} params.cursorSize - Size of the cursor in pixels
 * @param {React.RefObject} params.cursorRef - Reference to the cursor element
 * @param {boolean} params.centerOnMap - Whether to center the cursor (true) or use half size (false)
 * @param {(isHovered: boolean) => void} params.onHoverChange - Callback function when hover state changes
 * @returns {Object} Object containing mouse event handlers
 */
const useCustomCursor = ({
  cursorSize,
  cursorRef,
  centerOnMap = false,
  onHoverChange,
}) => {
  const cursorCenter = centerOnMap ? cursorSize : cursorSize / 2

  /**
   * Handles mouse movement and updates cursor position
   * Uses requestAnimationFrame for smooth performance
   */
  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      requestAnimationFrame(() => {
        if (!cursorRef.current) return
        const container = cursorRef.current.parentElement
        if (!container) return

        // Get coordinates relative to parent container
        const rect = container.getBoundingClientRect()
        const offsetX = event.clientX - rect.left
        const offsetY = event.clientY - rect.top

        // Apply position
        cursorRef.current.style.left = `${offsetX - cursorCenter}px`
        cursorRef.current.style.top = `${offsetY - cursorCenter}px`
      })
    },
    [cursorRef, cursorCenter]
  )

  /**
   * Handles mouse enter events
   * Initializes cursor position and starts tracking mouse movement
   */
  const handleMouseEnter = useCallback(
    (event: MouseEvent) => {
      onHoverChange(true)
      if (cursorRef.current) {
        const container = cursorRef.current.parentElement
        if (container) {
          const rect = container.getBoundingClientRect()
          const offsetX = event.clientX - rect.left
          const offsetY = event.clientY - rect.top

          cursorRef.current.style.left = `${offsetX - cursorCenter}px`
          cursorRef.current.style.top = `${offsetY - cursorCenter}px`
        }
      }
      document.addEventListener('mousemove', handleMouseMove)
    },
    [handleMouseMove, cursorRef, cursorCenter, onHoverChange]
  )

  /**
   * Returns memoized object containing event handlers
   * @returns {Object} Object with handleMouseEnter and handleMouseLeave functions
   */
  return useMemo(
    () => ({
      handleMouseEnter,
      handleMouseLeave: () => {
        onHoverChange(false)
        document.removeEventListener('mousemove', handleMouseMove)
      },
    }),
    [onHoverChange, handleMouseMove, handleMouseEnter]
  )
}

export default useCustomCursor
