'use client'

import React, { useState, useEffect } from 'react'
import { ArrowUpRight } from '@phosphor-icons/react/dist/ssr'
import Image from 'next/image'
import useCustomCursor from '~@Hooks/useCustomCursor'
import { twMerge } from 'tailwind-merge'

interface ICursorCustomProps {
  cursorSize?: number
  picture?: string
  centerOnMap?: boolean
}

const CursorCustom = ({
  cursorSize = 92,
  picture,
  centerOnMap = true,
}: ICursorCustomProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const cursorRef = React.useRef<HTMLDivElement>(null)

  const { handleMouseEnter, handleMouseLeave } = useCustomCursor({
    cursorSize,
    cursorRef,
    centerOnMap,
    onHoverChange: setIsHovered,
  })

  useEffect(() => {
    const parentElement = cursorRef.current?.parentElement
    if (parentElement) {
      parentElement.addEventListener('mouseenter', handleMouseEnter)
      parentElement.addEventListener('mouseleave', handleMouseLeave)
    }
    return () => {
      if (parentElement) {
        parentElement.removeEventListener('mouseenter', handleMouseEnter)
        parentElement.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [handleMouseEnter, handleMouseLeave])

  const cursorClasses = [
    'CursorCustom',
    'block',
    'absolute top-0 left-0 translate-[50%]',
    `flex items-center justify-center ${picture ? '' : 'p-lg'}`,
    `w-[${cursorSize}px] h-[${cursorSize}px]`,
    'bg-brand-secondary text-white-white',
    'rounded-full',
    'pointer-events-none',
    'transform transition ease-in-out',
    'z-[1000]',
    'will-change-transform',
    isHovered ? 'animate-cursor-appear' : 'animate-cursor-disappear',
  ].join(' ')

  const CursorPicture = picture ? (
    <Image src={picture} alt="cursor" width={cursorSize} height={cursorSize} />
  ) : (
    <ArrowUpRight size={cursorSize / 2} />
  )

  const offset = cursorSize / 2
  return (
    <span ref={cursorRef} className={twMerge('', cursorClasses)}>
      {CursorPicture}
    </span>
  )
}

export default CursorCustom
