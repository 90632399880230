import LinkItem from '~@Atoms/link-item/link-item'
import { ArrowUpRight, ArrowDown } from '@phosphor-icons/react/dist/ssr'
import { IButton } from '~@Types/components/IButton'
import { twMerge } from 'tailwind-merge'

export default function ButtonPrimary({
  className,
  link,
  label,
  isAlternate,
  isReversed,
  isAnchor,
}: IButton) {
  const buttonStyles = {
    default: 'bg-brand-primary text-white-white',
    reversed: 'bg-white-white text-brand-primary',
    alternate: 'bg-black-black text-white-white',
  }

  const style = isReversed
    ? buttonStyles.reversed
    : isAlternate
      ? buttonStyles.alternate
      : buttonStyles.default

  const labelStyle = `${style} group px-md py-sm rounded-full inline-block`

  const arrowStyle = `rounded-full ease-in-out transition duration-300 ${style} p-sm group-hover:translate-x-xs ${
    !isAnchor ? 'group-hover:rotate-45' : ''
  }`

  const iconColor = isReversed ? '#7D003D' : isAlternate ? '#FFFFFF' : '#FFFFFF'

  return (
    <LinkItem
      className={twMerge(
        'ButtonPrimary group inline-flex items-center typeface-button1-special',
        className
      )}
      {...link}
    >
      <span className={labelStyle}>{label}</span>
      <span aria-hidden="true" className={arrowStyle}>
        {isAnchor ? (
          <ArrowDown size={16} color={iconColor} weight="bold" />
        ) : (
          <ArrowUpRight size={16} color={iconColor} weight="bold" />
        )}
      </span>
    </LinkItem>
  )
}
