import { useState, useRef } from 'react'

const useShowMore = () => {
  const [showAll, setShowAll] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(true)
  const fourthItemRef = useRef<HTMLLIElement>(null)

  const handleShowMore = () => {
    setIsButtonVisible(false)
    setShowAll(true)

    // Attendre que les éléments soient rendus avant de faire défiler
    setTimeout(() => {
      if (fourthItemRef.current) {
        fourthItemRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  return { showAll, isButtonVisible, handleShowMore, fourthItemRef }
}

export default useShowMore
